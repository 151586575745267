<template>
    <div class="content flex">
        <div class="edit">
            <el-form :model="edit" :rules="rules" ref="ruleForm" class="tb-form" label-width="140px"
                     label-position="right">
                <el-form-item label="优惠券名称：" prop="coupon_name">
                    <el-input v-model="edit.coupon_name" placeholder="请输入优惠券名字"></el-input>
                    <span class="tips">优惠券名称示例；满XX减XX元</span>
                </el-form-item>
                <el-form-item label="起止时间：" prop="date">
                    <el-date-picker
                            v-model="edit.date"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            :clearable="false"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                    />
                </el-form-item>
                <!-- <el-form-item label="优惠券金额：">
                  <el-input v-model="edit.price"></el-input>
                </el-form-item> -->
                <el-form-item label="满：" prop="up_amount">
                    <div class="flex">
                        <el-input v-model="edit.up_amount" style="width: 224px;" placeholder="请输入(元)"></el-input>
                        <span style="color: rgba(0, 0, 0, 0.85); padding-left: 16px;">可用</span>
                    </div>
                </el-form-item>
                <el-form-item label="减：" prop="cutoff_amount">
                    <el-input v-model="edit.cutoff_amount" style="width: 224px;" placeholder="请输入(元)"></el-input>
                </el-form-item>
                <el-form-item label="有效期类型：" prop="effective_type">
                    <el-radio-group v-model="edit.effective_type">
                        <el-radio :label="1">按天数</el-radio>
                        <el-radio :label="2">按时间</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="有效期：" v-show="2 === edit.effective_type">
                    <el-date-picker
                            v-model="edit.effective_date"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            :clearable="false"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                    />
                </el-form-item>
                <el-form-item label="有效期：" prop="effective_day" v-show="1 === edit.effective_type">
                    <div class="flex">
                        <el-input v-model="edit.effective_day" placeholder="请输入天数"></el-input>
                        <span style="color: rgba(0, 0, 0, 0.85); padding-left: 16px;">天</span>
                    </div>
                </el-form-item>
                <el-form-item label="数量：" prop="stock">
                    <div class="flex">
                        <el-input v-model="edit.stock" placeholder="请输入数量"></el-input>
                        <span style="color: rgba(0, 0, 0, 0.85); padding-left: 16px;">张</span>
                    </div>
                </el-form-item>
                <el-form-item label="优惠券规则：" prop="rules">
                    <el-input v-model="edit.rules" type="textarea" placeholder="请输入优惠券规则"></el-input>
                    <div class="tips">查看优惠券<span class="demo-text" @click="toDemo">规则示例</span></div>
                </el-form-item>
                <!-- <el-form-item label="是否与Vip会员卡共享：" class="specail-form-item">
                    <el-radio-group v-model="edit.enable_vip">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item label="生效状态：">
                    <el-radio-group v-model="edit.status">
                        <el-radio :label="1">生效中</el-radio>
                        <el-radio :label="0">未生效</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onConfirm">确定</el-button>
                    <el-button @click="onCancel">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="demo">
            <h3 class="title">优惠券示例</h3>
            <img src="https://ddimage.topboom0912.com/admin_static_img/demo/coupon.png" alt="demo" width="298px">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CouponAdd',
        data() {
            return {
                id: null,
                edit: {
                    effective_type: 1,
                    expire_time: '',
                    effective_time: '',
                    effective_date: '',
                    coupon_name: '',
                    coupon_type: 1,
                    up_amount: '',
                    cutoff_amount: '',
                    effective_day: '',
                    stock: '',
                    rules: '',
                    enable_vip: 0,
                    status: 1,
                    date: null
                },
                rules: {
                    coupon_name: [{required: true, message: '请输入名称'}],
                    up_amount: [{required: true, message: '请输入金额'}],
                    cutoff_amount: [{required: true, message: '请输入金额'}],
                    // effective_day: [{required: true, message: '请输入天数'}],
                    stock: [{required: true, message: '请输入数量'}],
                    rules: [{required: true, message: '请输入规则'}],
                    date: [{required: true, message: '请输入选择时间'}],
                    effective_type: [{required:true, message:'请选择有效期类型'}]
                },
                categroyOptions: [],
                statusOptions: []
            }
        },
        mounted() {
            this.id = this.$route.query.id * 1 || null
            if (this.id) {
                this.getDetail()
            }
        },
        methods: {
            async getDetail() {
                const params = {
                    id: this.id
                }
                const url = 'admin/coupon/detail'
                this.$https.get(url, {params}).then(res => {
                    if (!res) return
                    this.edit.effective_type = res.effectiveType
                    this.edit.effective_date = [this.$dayjs(res.effectiveTime).format('YYYY-MM-DD HH:mm:ss'), this.$dayjs(res.expireTime).format('YYYY-MM-DD HH:mm:ss')]
                    this.edit.coupon_name = res.couponName
                    this.edit.coupon_type = res.couponType
                    this.edit.up_amount = this.$np.divide(res.upAmount, 100)
                    this.edit.cutoff_amount = this.$np.divide(res.cutoffAmount, 100)
                    this.edit.effective_day = res.effectiveDay
                    this.edit.stock = res.stock
                    this.edit.rules = res.rules
                    this.edit.enable_vip = res.enableVip
                    this.edit.status = res.status
                    this.edit.date = [this.$dayjs(res.startTime).format('YYYY-MM-DD HH:mm:ss'), this.$dayjs(res.endTime).format('YYYY-MM-DD HH:mm:ss')]
                })
            },
            async onConfirm() {
                const rule = await new Promise(resolve => {
                    this.$refs.ruleForm.validate((valid) => {
                        resolve(valid)
                    })
                })
                if (!rule) return
                const {effective_type,coupon_name, coupon_type, effective_day, stock, rules, enable_vip, status, date, effective_date} = this.edit
                const params = {
                    effective_type,
                    coupon_name,
                    coupon_type,
                    "up_amount": this.$np.times(this.edit.up_amount, 100),
                    "cutoff_amount": this.$np.times(this.edit.cutoff_amount, 100),
                    effective_day,
                    stock,
                    rules,
                    enable_vip,
                    status,
                    effective_time: effective_date[0],
                    expire_time: effective_date[1],
                    start_time: date[0],
                    end_time: date[1]
                }
                if (this.id) params.id = this.id
                const url = 'admin/coupon/edit'
                this.$https.post(url, params).then(res => {
                    if (res) this.$router.push('/coupon/list')
                })
            },
            onCancel() {
                this.$router.push('/coupon/list')
            },
            toDemo() {
                this.$alert('<img src="https://ddimage.topboom0912.com/admin_static_img/demo/coupon_demo.png" alt="demo" width="410px">', '', {
                    dangerouslyUseHTMLString: true,
                    customClass: 'tb-demo-dialog'
                })
            }
        }
    }
</script>

<style>
    .tb-form .specail-form-item .el-form-item__label {
        width: 165px !important;
    }
</style>

<style scoped lang="scss">
    .edit {
        padding-right: 70px;
        border-right: 1px solid #E8E8E8;
        box-sizing: border-box;

        .tb-form {
            width: 610px;

            .tips {
                padding-top: 8px;
                color: rgba(0, 0, 0, 0.45);
            }

            .demo-text {
                color: #1890FF;
                cursor: pointer;
                font-weight: 600;
            }
        }
    }

    .demo {
        padding-left: 70px;

        .title {
            margin: 0;
            padding-bottom: 16px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
        }
    }
</style>
